<template>
  <v-container style="padding: 8%" fluid>
    <h1 class="mb-10">{{ $t("privacy.main-title") }}</h1>
    <p class="mt-5 mb-5">
      {{ $t("privacy.content11") }}
    </p>
    <p class="mt-5 mb-5">
      {{ $t("privacy.content12") }}
    </p>
    <p class="mt-5 mb-5">
      {{ $t("privacy.content13") }}
    </p>

    <h2 class="mt-8 mb-7">
      {{ $t("privacy.section1.title") }}
    </h2>
    <p>
      {{ $t("privacy.section1.content1") }}
    </p>
    <p>
      {{ $t("privacy.section1.content2") }}
    </p>
    <p>
      {{ $t("privacy.section1.content3") }}
    </p>
    <p>
      {{ $t("privacy.section1.content4") }}
    </p>
    <p class="mb-5">
      {{ $t("privacy.section1.content5") }}
    </p>

    <h2 class="mt-8 mb-7">
      {{ $t("privacy.section2.title") }}
    </h2>
    <p class="mb-5">
      {{ $t("privacy.section2.content") }}
    </p>

    <h2 class="mt-8 mb-7">
      {{ $t("privacy.section3.title") }}
    </h2>
    <p>
      {{ $t("privacy.section3.content1") }}
    </p>
    <p class="mb-5">
      {{ $t("privacy.section3.content2") }}
    </p>

    <h2 class="mt-8 mb-7">
      {{ $t("privacy.section4.title") }}
    </h2>
    <p class="mb-5">
      {{ $t("privacy.section4.content1") }}
    </p>
    <p class="mb-5">
      {{ $t("privacy.section4.content2") }}
    </p>

    <h2 class="mt-8 mb-7">
      {{ $t("privacy.section5.title") }}
    </h2>
    <p class="mb-5">
      {{ $t("privacy.section5.content1") }}
    </p>
    <p class="mb-5">
      {{ $t("privacy.section5.content2") }}
    </p>

    <h2 class="mt-8 mb-7">
      {{ $t("privacy.section6.title") }}
    </h2>
    <p class="mb-5">
      {{ $t("privacy.section6.content") }}
    </p>
    <h3 class="mt-5 mb-7">{{ $t("privacy.section6.form.main-title") }}</h3>
    <p class="mb-5">
      {{ $t("privacy.section6.form.content1") }}
    </p>
    <p class="mb-5">
      <b>{{ $t("privacy.section6.form.section1.title") }}</b> :
      {{ $t("privacy.section6.form.section1.content") }}
    </p>

    <p class="mb-5">
      <b>{{ $t("privacy.section6.form.section2.title") }}</b> :
    </p>
    <ul>
      <li>{{ $t("privacy.section6.form.section2.list1") }}</li>
      <li>{{ $t("privacy.section6.form.section2.list2") }}</li>
    </ul>
    <p class="mt-5 mb-5">
      {{ $t("privacy.section6.form.section2.content1") }}
    </p>
    <p class="mb-5">
      {{ $t("privacy.section6.form.section2.content2") }}
    </p>
    <p>{{ $t("privacy.section6.form.section2.contact.name") }}</p>
    <p>{{ $t("privacy.section6.form.section2.contact.mail") }}</p>
    <p>{{ $t("privacy.section6.form.section2.contact.tel") }}</p>
    <p>{{ $t("privacy.section6.form.section2.contact.address") }}</p>
    <p class="mt-7">
      {{ $t("privacy.section6.form.section2.content3") }}
    </p>

    <h2 class="mt-8 mb-7">
      {{ $t("privacy.section7.title") }}
    </h2>
    <p class="mb-5">
      {{ $t("privacy.section7.content1") }}
    </p>
    <p class="mb-5">
      {{ $t("privacy.section7.content2") }}
    </p>
    <p class="mb-5">
      {{ $t("privacy.section7.content3") }}
    </p>
    <ul class="mb-5">
      <li class="mb-2">
        {{ $t("privacy.section7.list1") }}
      </li>
      <li>
        {{ $t("privacy.section7.list2") }}
      </li>
    </ul>
    <p class="mb-5">
      {{ $t("privacy.section7.content4") }}
    </p>
  </v-container>
</template>

<script>
export default {
  name: "Privacy",
};
</script>
